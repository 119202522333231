import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScriptLoaderService } from 'angular-google-charts';
import { Subscription } from 'rxjs';
import { DemoService } from 'src/app/services/demo.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-decision-table-dashboard',
  templateUrl: './decision-table-dashboard.component.html',
  styleUrls: ['./decision-table-dashboard.component.scss']
})
export class DecisionTableDashboardComponent implements OnInit {

  private _activeRouteSubscription: Subscription;

  id: any;

  constructor(private demoService: DemoService, private activeRoute: ActivatedRoute, private loaderService: ScriptLoaderService) { }

  evaluation_sessions_by_row: number = 5;
  private evaluation_sessions: any[] =  [];
  evaluation_sessions_row_1 = [];
  evaluation_sessions_row_2 = [];

  feedbackData:[] = [];
  criteriaData: [] = [];
  analysisType = 0;

  displayedColumnsCriteria: string[] = ['criteriatitle', 'suggest', 'vote', 'risk', 'contradiction'];
  displayedColumns: string[] = ['expert', 'answers', 'time', 'cost', 'total_cost', 'evaluation_session', 'new_proposal', 'evidence'];

  ngOnInit(): void {

    //  controllo se sono in modifica o in creazione
    this._activeRouteSubscription = this.activeRoute.params.subscribe(routeParams => {
      this.id = routeParams.id_decision;

      //  carico i grafici
      this.loaderService.loadChartPackages('corechart').subscribe(() => {
        this.init();
      });
    });

  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    if (this._activeRouteSubscription)
      this._activeRouteSubscription.unsubscribe();
  }


  init() {

    //  TODO_MICHELE: versione demo per Enzo
    if (environment.demo) {

      switch (this.id) {
        case "1":
          var modelDemo = this.demoService.casoSarcomatoidDashboard;

          console.log(modelDemo);
          this.evaluation_sessions = modelDemo.evaluation_sessions.slice();
          this.feedbackData = modelDemo.feedbackData.slice();
          this.criteriaData = modelDemo.criteriaData.slice();
          this.analysisType = modelDemo.analysis_type;
          break;
        case "2":
          var modelDemo = this.demoService.casoYamalDashboard;

          console.log(modelDemo);
          this.evaluation_sessions = modelDemo.evaluation_sessions.slice();
          this.feedbackData = modelDemo.feedbackData.slice();
          this.criteriaData = modelDemo.criteriaData.slice();
          this.analysisType = modelDemo.analysis_type;
          setTimeout(() => {
            this.drawChartTargets();
            this.drawChartWeightTargets();
          }, 500);
          break;

        default:
          break;
      }

    }

    this.evaluation_sessions_row_1 = this.evaluation_sessions.splice(0, this.evaluation_sessions_by_row);
    this.evaluation_sessions_row_2 = this.evaluation_sessions;
  }



  /** Disegna i grafici delle proposte */
  drawChartTargets() {

    let data = google.visualization.arrayToDataTable([
      ["Title", "Cost", "Delivery", 'Quality', 'Risk', { role: 'annotation' }],
      ["No Modularization", {v: 82.9, f: 82.9+'%'}, {v: 129.77, f: 129.77+'%'}, {v: 84.87, f: 84.87+'%'}, {v: 107.89, f: 107.89+'%'}, ''],
      ["Modularization", {v: 167.53, f: 167.53+'%'}, {v: 252.38, f: 252.38+'%'}, {v: 164.94, f: 164.94+'%'}, {v: 223.9, f: 223.9+'%'}, ''],
    ]);

    let view = new google.visualization.DataView(data);

    let chart = new google.visualization.BarChart(document.getElementById("chart_div_target"));

    chart.draw(view, {
      height: 400,
      legend: { position: 'top', maxLines: 3 },
      bar: { groupWidth: '75%' },
      isStacked: true
    });
  }

  /** Disegna i grafici dei pesi degli obiettivi */
  drawChartWeightTargets() {

    let data = google.visualization.arrayToDataTable([['Task', 'Hours per Day'],
      ['Risk', 27],
      ['Delivery', 31],
      ['Cost', 21],
      ['Quality', 21]
    ]);

    let view = new google.visualization.DataView(data);

    let chart = new google.visualization.PieChart(document.getElementById("chart_div_weight_target"));

    chart.draw(view, {
      height: 400,
      legend: { position: 'top', maxLines: 3 }
    });
  }


}
