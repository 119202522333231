<!-- Blocco 1 -->
<div class="container-fluid accordion-container">
  <div class="container">
      <mat-accordion class="accordion" multi>
          <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                  <mat-panel-title>
                      <span class="index">1</span>
                      Timeline
                  </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="row">
                  <div class="stepper-container col-12">
                      <p class="headline float-left">
                          Evaluation Session
                      </p>
                      <p class="headline float-right">
                          Report
                      </p>
                      <div class="timeline">
                          <div class="step-container" [ngClass]="session.tracker == true ? 'current' : ''" *ngFor="let session of evaluation_sessions_row_1; let i = index">
                            <ng-container>
                              <div class="step">{{i + 1}}</div>
                              <div class="step-label">{{session.decision_start}} <br> {{session.decision_end}}</div>
                            </ng-container>
                          </div>
                          <div class="step-container icon-container" *ngIf="evaluation_sessions_row_1.length < evaluation_sessions_by_row">
                            <div class="step">
                                <i class="icon-Flag"></i>
                            </div>
                          </div>
                          <!-- <div class="step-container current">
                              <div class="step">2</div>
                              <div class="step-label">17 Luglio 2020</div>
                          </div> -->
                      </div>
                  </div>
                  <div class="stepper-container col-12" *ngIf="evaluation_sessions_row_1.length >= evaluation_sessions_by_row">
                      <div class="timeline until-end">
                          <div class="step-container" [ngClass]="session.tracker == true ? 'current' : ''" *ngFor="let session of evaluation_sessions_row_2; let i = index">
                            <ng-container>
                              <div class="step">{{evaluation_sessions_by_row + i+1}}</div>
                              <div class="step-label">{{session.decision_start}}</div>
                            </ng-container>
                          </div>
                          <div class="step-container icon-container">
                            <div class="step">
                                <i class="icon-Flag"></i>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div class="row col-12 d-flex justify-content-end mb-3">
                  <button mat-flat-button color="primary" [routerLink]="['/decision/'+id+'/show']" routerLinkActive="router-link-active" >Go to the table management</button>
              </div>

          </mat-expansion-panel>
      </mat-accordion>
  </div>
</div>

<div class="container-fluid accordion-container">
    <div class="container">
        <mat-accordion class="accordion" multi>
            <mat-expansion-panel expanded>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="index">2</span>
                        Evaluation sessions concluded
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <ng-container *ngIf="analysisType == 2">
                  <div class="row">
                    <div class="container px-0">

                      <div class="row">

                      </div>

                      <div class="row">
                        <div class="col-12">
                          <div class="box-info mb-4 position-relative" *ngIf="criteriaData.length > 0">

                            <mat-table [dataSource]="criteriaData" style="width: 100%;">

                              <!-- Name Column -->
                              <ng-container matColumnDef="criteriatitle">
                                <mat-header-cell *matHeaderCellDef>Criteria</mat-header-cell>
                                <mat-cell *matCellDef="let element">{{element.title}} </mat-cell>
                              </ng-container>

                              <!-- Sentiment Column -->
                              <ng-container matColumnDef="suggest">
                                <mat-header-cell *matHeaderCellDef style="justify-content:center; text-align: center;">Suggestion</mat-header-cell>
                                <mat-cell *matCellDef="let element" style="justify-content:center">
                                  <mat-chip-list>
                                    <mat-chip *ngIf="element.accept" style="justify-content:center; background-color: #00bc1d;" class="text-white">Accept</mat-chip>
                                    <mat-chip *ngIf="!element.accept" style="justify-content:center; background-color: #ff0000;" class="text-white">Review</mat-chip>
                                  </mat-chip-list>
                                </mat-cell>
                              </ng-container>

                              <!-- Feedback Column -->
                              <ng-container matColumnDef="vote">
                                <mat-header-cell *matHeaderCellDef style="justify-content:center; text-align: center;">Votes</mat-header-cell>
                                <mat-cell *matCellDef="let element" style="justify-content:center">
                                  <div class="progress" style="width: 100%; height: 25px; margin: 10px;">
                                    <div class="progress-bar bg-success" role="progressbar" [style.width.%]="element.vote.graph_vote_yes">{{element.vote.graph_vote_yes}}%</div>
                                    <div class="progress-bar bg-danger" role="progressbar" [style.width.%]="element.vote.graph_vote_no">{{element.vote.graph_vote_no}}%</div>
                                    <div class="progress-bar bg-secondary" role="progressbar" [style.width.%]="element.vote.graph_vote_none">{{element.vote.graph_vote_none}}%</div>
                                  </div>
                                </mat-cell>
                              </ng-container>

                              <!-- True False Column -->
                              <ng-container matColumnDef="risk">
                                <mat-header-cell *matHeaderCellDef style="justify-content:center; text-align: center;">Degree of certainty</mat-header-cell>
                                <mat-cell *matCellDef="let element" style="justify-content: center; text-align: center;">
                                  <div class="progress" style="width: 100%; height: 25px; margin: 10px;">
                                    <div class="progress-bar bg-primary" role="progressbar" [style.width.%]="element.risk">{{element.risk}}%</div>
                                  </div>
                                </mat-cell>
                              </ng-container>

                              <!-- Confidence Column -->
                              <ng-container matColumnDef="contradiction">
                                <mat-header-cell *matHeaderCellDef style="justify-content:center; text-align: center;">Degree of contradiction</mat-header-cell>
                                <mat-cell *matCellDef="let element" style="justify-content:center; text-align: center;">
                                  <div class="progress" style="width: 100%; height: 25px; margin: 10px;">
                                    <div class="progress-bar bg-danger" role="progressbar" [style.width.%]="element.contradiction">{{element.contradiction}}%</div>
                                  </div>
                                </mat-cell>
                              </ng-container>

                              <mat-header-row *matHeaderRowDef="displayedColumnsCriteria"></mat-header-row>
                              <mat-row *matRowDef="let row; columns: displayedColumnsCriteria;"></mat-row>
                            </mat-table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row pl-4 header-title">
                    <p class="mb-0 mr-2">Time taken to compile</p>
                    <span class="main-color text-uppercase">9 HOURS</span> &nbsp;
                    <p class="mb-0 mr-2">Total value</p>
                    <span class="main-color text-uppercase">2250 €</span>
                  </div>

                  <div class="row">
                    <!-- Tabella Feedback-->
                      <div class="col-12 px-2" *ngIf="feedbackData">
                        <mat-table [dataSource]="feedbackData" style="width: 100%;">

                          <!-- Name Column -->
                          <ng-container matColumnDef="expert">
                            <mat-header-cell *matHeaderCellDef style="justify-content:center; text-align: center;">Experts invited</mat-header-cell>
                            <mat-cell *matCellDef="let element" style="justify-content:center">{{element.expert}} </mat-cell>
                          </ng-container>

                          <!-- Sentiment Column -->
                          <ng-container matColumnDef="answers">
                            <mat-header-cell *matHeaderCellDef style="justify-content:center; text-align: center;">Survey responses</mat-header-cell>
                            <mat-cell *matCellDef="let element" style="justify-content:center">
                              <mat-chip-list>
                                <mat-chip *ngIf="element.answers" style="justify-content:center; background-color: #00bc1d;" class="text-white">Yes</mat-chip>
                                <mat-chip *ngIf="!element.answers" style="justify-content:center; background-color: #ff0000;" class="text-white">No</mat-chip>
                              </mat-chip-list>
                            </mat-cell>
                          </ng-container>

                          <!-- Feedback Column -->
                          <ng-container matColumnDef="time">
                            <mat-header-cell *matHeaderCellDef style="justify-content:center; text-align: center;">Time for evaluation </mat-header-cell>
                            <mat-cell *matCellDef="let element" style="justify-content:center"> {{element.time}} </mat-cell>
                          </ng-container>

                          <!-- True False Column -->
                          <ng-container matColumnDef="cost">
                            <mat-header-cell *matHeaderCellDef style="justify-content:center; text-align: center;">Hourly cost</mat-header-cell>
                            <mat-cell *matCellDef="let element" style="justify-content: center; text-align: center;">{{element.cost}}</mat-cell>
                          </ng-container>

                          <ng-container matColumnDef="total_cost">
                            <mat-header-cell *matHeaderCellDef style="justify-content:center; text-align: center;">Estimated value evaluation</mat-header-cell>
                            <mat-cell *matCellDef="let element" style="justify-content: center; text-align: center;">{{element.total_cost}}</mat-cell>
                          </ng-container>

                          <!-- Confidence Column -->
                          <ng-container matColumnDef="evaluation_session">
                            <mat-header-cell *matHeaderCellDef style="justify-content:center; text-align: center;">Evaluation session</mat-header-cell>
                            <mat-cell *matCellDef="let element" style="justify-content:center; text-align: center;">{{element.evaluation_session}}</mat-cell>
                          </ng-container>

                          <!-- evidence Column -->
                          <ng-container matColumnDef="new_proposal">
                            <mat-header-cell *matHeaderCellDef style="justify-content:center; text-align: center;">Formulation of new proposals</mat-header-cell>
                            <mat-cell *matCellDef="let element" style="justify-content:center">
                              <mat-chip-list>
                                <mat-chip *ngIf="element.new_proposal">Yes</mat-chip>
                                <mat-chip *ngIf="!element.new_proposal">No</mat-chip>
                              </mat-chip-list>
                            </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="evidence">
                            <mat-header-cell *matHeaderCellDef style="justify-content:center; text-align: center;">Evidence attached</mat-header-cell>
                            <mat-cell *matCellDef="let element" style="justify-content:center">
                              <mat-chip-list>
                                <mat-chip *ngIf="element.evidence">Yes</mat-chip>
                                <mat-chip *ngIf="!element.evidence">No</mat-chip>
                              </mat-chip-list>
                            </mat-cell>
                          </ng-container>

                          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                        </mat-table>
                      </div>

                      <!-- Text Area -->
                      <!-- <div class="col-12 px-2">
                          <div class="box flex-column my-0 pb-0 pt-3 px-3 bordered">
                              <mat-form-field>
                                  <mat-label>Description</mat-label>
                                  <textarea class="description" matInput rows="2" name="criterio_description{{i}}"
                                      placeholder="Inserire descrizione"></textarea>
                              </mat-form-field>
                          </div>
                      </div> -->
                  </div>
                </ng-container>

                <ng-container *ngIf="analysisType == 1">
                  <div class="row" >
                    <div class="container px-0">

                      <div class="row">

                      </div>

                      <div class="row">
                        <div class="col-8">
                          <div class="box-info mb-4 position-relative">
                            <div style="width: 100%;" id="chart_div_target"></div>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="box-info mb-4 position-relative">
                            <div style="width: 100%;" id="chart_div_weight_target"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row pl-4 header-title">
                    <p class="mb-0 mr-2">Time taken to compile</p>
                    <span class="main-color text-uppercase">9 HOURS</span> &nbsp;
                    <p class="mb-0 mr-2">Total value</p>
                    <span class="main-color text-uppercase">2250 €</span>
                  </div>

                  <div class="row">
                    <!-- Tabella Feedback-->
                      <div class="col-12 px-2" *ngIf="feedbackData">
                        <mat-table [dataSource]="feedbackData" style="width: 100%;">

                          <!-- Name Column -->
                          <ng-container matColumnDef="expert">
                            <mat-header-cell *matHeaderCellDef style="justify-content:center; text-align: center;">Experts invited</mat-header-cell>
                            <mat-cell *matCellDef="let element" style="justify-content:center">{{element.expert}} </mat-cell>
                          </ng-container>

                          <!-- Sentiment Column -->
                          <ng-container matColumnDef="answers">
                            <mat-header-cell *matHeaderCellDef style="justify-content:center; text-align: center;">Survey responses</mat-header-cell>
                            <mat-cell *matCellDef="let element" style="justify-content:center">
                              <mat-chip-list>
                                <mat-chip *ngIf="element.answers" style="justify-content:center; background-color: #00bc1d;" class="text-white">Yes</mat-chip>
                                <mat-chip *ngIf="!element.answers" style="justify-content:center; background-color: #ff0000;" class="text-white">No</mat-chip>
                              </mat-chip-list>
                            </mat-cell>
                          </ng-container>

                          <!-- Feedback Column -->
                          <ng-container matColumnDef="time">
                            <mat-header-cell *matHeaderCellDef style="justify-content:center; text-align: center;">Time for evaluation </mat-header-cell>
                            <mat-cell *matCellDef="let element" style="justify-content:center"> {{element.time}} </mat-cell>
                          </ng-container>

                          <!-- True False Column -->
                          <ng-container matColumnDef="cost">
                            <mat-header-cell *matHeaderCellDef style="justify-content:center; text-align: center;">Hourly cost</mat-header-cell>
                            <mat-cell *matCellDef="let element" style="justify-content: center; text-align: center;">{{element.cost}}</mat-cell>
                          </ng-container>

                          <ng-container matColumnDef="total_cost">
                            <mat-header-cell *matHeaderCellDef style="justify-content:center; text-align: center;">Estimated value evaluation</mat-header-cell>
                            <mat-cell *matCellDef="let element" style="justify-content: center; text-align: center;">{{element.total_cost}}</mat-cell>
                          </ng-container>

                          <!-- Confidence Column -->
                          <ng-container matColumnDef="evaluation_session">
                            <mat-header-cell *matHeaderCellDef style="justify-content:center; text-align: center;">Evaluation session</mat-header-cell>
                            <mat-cell *matCellDef="let element" style="justify-content:center; text-align: center;">{{element.evaluation_session}}</mat-cell>
                          </ng-container>

                          <!-- evidence Column -->
                          <ng-container matColumnDef="new_proposal">
                            <mat-header-cell *matHeaderCellDef style="justify-content:center; text-align: center;">Formulation of new proposals</mat-header-cell>
                            <mat-cell *matCellDef="let element" style="justify-content:center">
                              <mat-chip-list>
                                <mat-chip *ngIf="element.new_proposal">Yes</mat-chip>
                                <mat-chip *ngIf="!element.new_proposal">No</mat-chip>
                              </mat-chip-list>
                            </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="evidence">
                            <mat-header-cell *matHeaderCellDef style="justify-content:center; text-align: center;">Evidence attached</mat-header-cell>
                            <mat-cell *matCellDef="let element" style="justify-content:center">
                              <mat-chip-list>
                                <mat-chip *ngIf="element.evidence">Yes</mat-chip>
                                <mat-chip *ngIf="!element.evidence">No</mat-chip>
                              </mat-chip-list>
                            </mat-cell>
                          </ng-container>

                          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                        </mat-table>
                      </div>

                      <!-- Text Area -->
                      <!-- <div class="col-12 px-2">
                          <div class="box flex-column my-0 pb-0 pt-3 px-3 bordered">
                              <mat-form-field>
                                  <mat-label>Descrizione</mat-label>
                                  <textarea class="description" matInput rows="2" name="criterio_description{{i}}"
                                      placeholder="Inserire descrizione"></textarea>
                              </mat-form-field>
                          </div>
                      </div> -->
                  </div>
                </ng-container>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
